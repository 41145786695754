<template>
	<div :class="rootClasses">
		<div class="container">
			<h1 v-if="data.title">
				{{ data.title }}
			</h1>
			<div v-if="data.text" class="cs-rich-text" v-html="data.text"></div>
			<div v-if="data.cards" class="row cs-cards-list">
				<div v-for="(card, index) in data.cards" :key="`cs-card-${index}`"
					class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex" :class="[
						{ 'col-lg-4': data.cards.length == 3 },
						{ 'col-lg-6 col-md-6': data.cards.length == 2 },
						{ 'col-lg-12 col-sm-12 col-md-12': data.cards.length == 1 },
					]">
					<div class="cs-cards-card">
						<img v-if="card.image" :src="card.image" :alt="card.imgAltText" />
						<div v-if="(card.title || card.text || card.linkText)" class="cs-cards-card-info">
							<p v-if="card.title" class="cs-cards-card-title">
								{{ card.title }}
							</p>
							<div v-if="card.text" v-html="card.text" class="cs-cards-card-text"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Cards',
	components: {},
	props: {
		data: {
			type: Object,
			default: () => { },
		},
	},
	data() {
		return {

		}
	},
	computed: {
		rootClasses() {
			let result = {
				['cs-cards']: true,
				['theme-block']: true
			};

			if (this.data?.hasBackground) result = {
				...result,
				...{
					['has-background']: true,
					['theme-bg-colored']: true
				}
			}

			return result;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';

.cs-cards {
	h1 {
		@include title;

		margin-bottom: 0px;
		text-align: center;
	}

	.cs-rich-text {
		margin-top: $theme_items_margin;

		::v-deep {
			p {
				@include text;
			}

			* {
				text-align: center;

				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}

	&-list {
		margin-top: calc(#{$default_margin * 2} - #{$theme_items_margin});
	}

	div[class*="col-"] {
		margin-top: calc(#{$default_margin} * 2);
	}

	&-card {
		flex: 1 1 auto;
		background: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			// width: 100%;
			height: auto;
			object-fit: contain;
			object-position: contain;

			@media (max-width: 575px) {
				max-height: auto;
				width: 100%;
			}

			@media (min-width: 576px) {
				max-height: 300px;
			}
		}

		&-info {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: $theme_items_margin;
			transition: 0.3s ease-in-out all;
		}

		&-title {
			font-family: 'Montserrat';
			font-weight: 600;
			font-size: 20px;
			text-align: center;
			color: #313131;
			margin-bottom: 0px;
			margin-top: 15px;

		}

		&-text {
			font-family: 'Montserrat';
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #313131;
			margin-top: 15px;
			max-width: 175px;

			::v-deep {
				* {
					font-family: 'Montserrat';
					font-weight: normal;
					font-size: 16px;
					line-height: 16px;
					text-align: center;
					color: #313131;
					margin-top: 15px;
					max-width: 175px;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-size: unset;
				}
			}
		}

		&:hover {
			.cs-cards-card-info {
				width: 100%;
				background: #fff;
                box-shadow: 0px 7px 29px rgba(28, 28, 28, 0.13);
			}
		}
	}
}</style>